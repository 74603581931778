import React, { createRef, useState, useContext, useEffect } from 'react';
import { Button, ListGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../App';
import CommonToast from '../../common/CommonToast';
import { CrawlData } from '../../../service/bookmark/model/Bookmark';
import './ModuleDetail.scss';
import { MegaTrendData } from '../../../service/megaTrend/model/MegaTrend';
import BookmarkContainer from '../../bookmark/BookmarkContainer';
import { MegaTrendDetailActionType, ShareActionType } from '../../../reducer/actions';
import { ShareType } from '../../../service/share/model/enum/Share.enum';
import { Rnd } from 'react-rnd';
import { ModuleDetailDefaultProps } from '../../../service/megaTrend/model/enum/MegaTrend.enum';
import useWindowDimensions from '../../../layouts/WindowDimensions';

export interface ModuleDetailProps {
  showModuleDetail: Function;
  cardContents?: MegaTrendData | undefined;
  moduleDetail: boolean;
}

const ModuleDetail: React.FC = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AuthContext);
  const [overlayRootClose, setOverlayRootClose] = useState<boolean>(true);
  const rndRef = createRef<Rnd>();
  const { width } = useWindowDimensions();
  const [isMaximized, setIsMaximized] = useState<boolean>(false);

  const temp = {
    crawlDataId: '',
    crawlDate: '',
    addDate: '',
    htmlContent: '',
    morphemeWords: '',
  };

  const calWidth = (): number => {
    let result: number = ModuleDetailDefaultProps.width;
    const autoSize: number = Math.round(width / 2);
    if (autoSize > result) {
      result = autoSize;
    }
    return result;
  };

  const calInitXaxios = (): number => {
    return width - calWidth();
  };

  const defaultModalProps = {
    x: calInitXaxios(),
    y: ModuleDetailDefaultProps.top,
    width: calWidth(),
    height: window.innerHeight - ModuleDetailDefaultProps.top,
  };

  useEffect(() => {
    if (!state.moduleDetail.isDetailOpen) {
      setIsMaximized(false);
      if (rndRef.current) {
        rndRef.current.updateSize({ width: defaultModalProps.width, height: defaultModalProps.height });
        rndRef.current.updatePosition({ x: defaultModalProps.x, y: defaultModalProps.y });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.moduleDetail.isDetailOpen]);

  useEffect(() => {
    if (rndRef.current) {
      rndRef.current.updateSize({ width: defaultModalProps.width, height: defaultModalProps.height });
      rndRef.current.updatePosition({ x: defaultModalProps.x, y: defaultModalProps.y });
    }
  }, [
    defaultModalProps.height,
    defaultModalProps.width,
    defaultModalProps.x,
    defaultModalProps.y,
    rndRef.current,
    width,
  ]);

  const handleOverlayShow = (rootClose: boolean) => {
    setOverlayRootClose(rootClose);
  };

  const toggleMaximized = () => {
    setIsMaximized(!isMaximized);
  };

  const hideDetail = () => {
    dispatch({
      type: MegaTrendDetailActionType.SHOW_MODULE_DETAIL,
      moduleDetail: { isDetailOpen: false },
    });
  };

  const openPptURL = () => {
    window.open(state.moduleDetail.detailData?.url, '_blank');
  };

  const setCrawlData = (cardContents: MegaTrendData | undefined): CrawlData => {
    let response;
    if (cardContents) {
      response = state.megaTrendService.getMegaTrendData(cardContents).crawlData;
    } else {
      response = temp;
    }
    return response;
  };

  const handleShowShareModal = () => {
    dispatch({
      type: ShareActionType.SHOW_SHARE_MODAL,
      show: true,
      sharedType: ShareType.MEGA_TREND,
      sharedDetails: [
        {
          title: state.moduleDetail.detailData?.keyword,
          source: state.moduleDetail.detailData?.source,
        },
      ],
    });
  };

  return (
    <Rnd
      ref={rndRef}
      className={`ModuleDetail ${isMaximized ? 'maximize' : ''} ${
        state.moduleDetail.isDetailOpen ? 'detail-show' : 'detail-hide'
      }`}
      dragAxis="x"
      default={defaultModalProps}
      minWidth={ModuleDetailDefaultProps.width}
      maxWidth="100%"
      bounds="window"
      dragHandleClassName={'module-detail-header'}
      disableDragging={isMaximized}
      enableResizing={isMaximized ? false : { left: true, right: true }}
      resizeHandleClasses={{ left: 'module-resize-left-handler', right: 'module-resize-right-handler' }}
    >
      <div className={`vscroll flex flex-one vbox bottom`}>
        <div className={'module-detail-header flex'}>
          {/* TODO: 상세화면 tab기능 정의 후 구현
          <div className={'detail-header-top'}>
            <ListGroup>
              <ListGroup.Item>
                <p>
                  {state.moduleDetail.detailData?.keyword}
                  <a href="#" className="close"></a>
                </p>
              </ListGroup.Item>
            </ListGroup>
            <div className="detail-header-top-button">
              <div className={'arrow-right cursor_pointer'}></div>
              <div
                className={'module-close-area cursor_pointer'}
                onClick={() => {
                  props.showModuleDetail(false);
                }}
              ></div>
            </div>
          </div>
          */}
          <div className={'detail-header-left flex hbox'}>
            <div className={'original-btn-area'}>
              <button className={'original-btn'}>
                {state.moduleDetail.detailData?.url ? (
                  <a href={state.moduleDetail.detailData?.url} target={'_blank'} className={'original-btn-atag'}>
                    {t('module.cardA.originalView')}
                  </a>
                ) : (
                  t('module.cardA.originalView')
                )}
              </button>
            </div>
            <div className={'full-screen-btn-area'}>
              <Button className="btn-icon" onClick={toggleMaximized}>
                <i className={isMaximized ? 'module-ic-minimize' : 'module-ic-maximize'}></i>
              </Button>
            </div>
            <OverlayTrigger
              trigger="click"
              rootClose={overlayRootClose}
              placement="bottom"
              overlay={
                <Popover id="popoverBookmarkContainer">
                  <BookmarkContainer
                    searchData={{
                      title: state.moduleDetail.detailData?.keyword || '',
                      content: state.moduleDetail.detailData?.content || '',
                    }}
                    crawlData={setCrawlData(state.moduleDetail.detailData)}
                    onOverlayRootClose={handleOverlayShow}
                  />
                </Popover>
              }
            >
              <div className={'bookmark-area'}></div>
            </OverlayTrigger>
            <div className={'module-share-area cursor_pointer'} onClick={handleShowShareModal}></div>
          </div>
          <div className={'detail-header-right flex hbox'}>
            <div className={'comment-area'}>{'7'}</div>
            <div className={'module-close-area cursor_pointer'} onClick={hideDetail}></div>
          </div>
        </div>
        <div className={'detail-body flex flex-one'}>
          <div className={'detail-body-top flex flex-one vbox'}>
            <div className={'detail-body-title flex'}>{state.moduleDetail.detailData?.keyword}</div>
            <div className={'detail-body-info flex hbox'}>
              <div className={'date'}>{state.moduleDetail.detailData?.year}</div>
              <div className={'author'}>&nbsp;&nbsp;&nbsp;&nbsp;{state.moduleDetail.detailData?.source}</div>
            </div>
            {/* TODO: 태그 기능 정의 후 구현 
            <div className={'detail-body-tag-list flex hbox'}>
              <div className={'tag'}>#{props.cardContents?.source}</div>
            </div>
            */}
            <div className={'detail-body-content flex vbox'}>
              {state.moduleDetail.detailData?.fileName && state.moduleDetail.detailData?.url && (
                <div className={'file-area flex'}>
                  <div className={'file-info flex vbox'} onClick={openPptURL}>
                    <div className={'name'}>{state.moduleDetail.detailData?.fileName}</div>
                    <div className={'capacity'}>
                      {state.moduleDetail.detailData?.fileName &&
                        state.moduleDetail.detailData?.fileName.substr(
                          state.moduleDetail.detailData?.fileName.lastIndexOf('.') + 1
                        ) + '  File'}
                    </div>
                  </div>
                </div>
              )}
              <div className={'content-area flex'}>{state.moduleDetail.detailData?.content}</div>
            </div>
          </div>
        </div>
      </div>
    </Rnd>
  );
};

export default ModuleDetail;
