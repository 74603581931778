import { Service } from '../model/Service';
import { Method } from '../model/Method';
import BaseService from '../BaseService';
import { CommonResponse } from '../model/CommonResponse';
import { InterestRequest } from './model/Interest';

export default class InterestService extends BaseService {
  async retrievePersonalInterests(): Promise<CommonResponse>  {
    const method = Method.GET;
    const url = '/v1/interests';
    const serviceName = Service.MIBE;
    const params = null;
    const body = null;
    let response: CommonResponse;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }

    return response;
  }

  async getInterestSearchResult(): Promise<CommonResponse>  {
    const method = Method.GET;
    const url = '/v1/interests/search';
    const serviceName = Service.MIBE;
    const params = null;
    const body = null;
    let response: CommonResponse;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }

    return response;
  }

  async savePersonalInterest(interests: InterestRequest): Promise<CommonResponse> {
    let method = Method.POST;
    const url = '/v1/interests';
    const serviceName = Service.MIBE;
    const params = null;
    const body = interests;
    let response: CommonResponse;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }
    return response;
  }

  async deletePersonalInterest(category: string): Promise<CommonResponse> {
    let method = Method.DELETE;
    const url = '/v1/interests';
    const serviceName = Service.MIBE;
    const params = {category: category};
    const body = null;
    let response: CommonResponse;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }
    return response;
  }

  async getInitialInterestSetupYn(): Promise<CommonResponse>  {
    const method = Method.GET;
    const url = '/v1/interests/initial-setup';
    const serviceName = Service.MIBE;
    const params = null;
    const body = null;
    let response: CommonResponse;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }

    return response;
  }

  async updateInterestInitialSetup(): Promise<CommonResponse> {
    let method = Method.PATCH;
    const url = '/v1/interests/initial-setup';
    const serviceName = Service.MIBE;
    const params = null;
    const body = null;
    let response: CommonResponse;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }
    return response;
  }
}
