import Economic0 from '../../../icons/economic_coin.png';
import Economic1 from '../../../icons/economic_drop.png';
import Economic2 from '../../../icons/economic_money.png';
import Economic3 from '../../../icons/economic_pile.png';
import Environment0 from '../../../icons/environment_island.png';
import Environment1 from '../../../icons/environment_nature.png';
import Environment2 from '../../../icons/environment_plant.png';
import Environment3 from '../../../icons/environment_pollution.png';
import political0 from '../../../icons/political_demonstration.png';
import political1 from '../../../icons/political_map.png';
import political2 from '../../../icons/political_speech.png';
import political3 from '../../../icons/political_vote.png';
import social0 from '../../../icons/social_change.png';
import social1 from '../../../icons/social_people.png';
import social2 from '../../../icons/social_spread.png';
import social3 from '../../../icons/social_stairs.png';
import technological0 from '../../../icons/technology_computer.png';
import technological1 from '../../../icons/technology_heart.png';
import technological2 from '../../../icons/technology_pyramid.png';
import technological3 from '../../../icons/technology_robot.png';
import sampleImage from '../../../icons/img-card-sample.png';
import { SearchData } from '../../model/Search';

const ImageArray = {
  Economic: [
    Economic0,
    Economic1,
    Economic2,
    Economic3
  ],
  Environment: [
    Environment0,
    Environment1,
    Environment2,
    Environment3
  ],
  Political: [
    political0,
    political1,
    political2,
    political3
  ],
  Social: [
    social0,
    social1,
    social2,
    social3
  ],
  Technological: [
    technological0,
    technological1,
    technological2,
    technological3
  ],
  SampleImage: [
    sampleImage
  ]
}

export { ImageArray }

export interface MegaTrendSourceList {
  timedOut: boolean;
  total: {
    value: number;
    relation: string;
  };
  maxScore: number;
  items: DomesticGroup[];
}

export interface DomesticGroup {
  domesticOverseas: string;
  items: SourceData[];
}

export interface SourceData {
  source: string;
  docCount: number;
}

export interface MegaTrendCategoryYearCondtion {
  domesticOverseas: string;
  source?: string;
}

export interface CategoryGroup {
  source: string;
  docCount: number;
}

export interface YearGroup {
  source: string;
  docCount: number;
}

export interface MegaTrendListSearchCondtion {
  size: number;
  page: number;
  domesticOverseas?: string;
  source?: string;
  category?: string;
  year?: string;
}

export interface MegaTrendDataList {
  timedOut: boolean;
  total: {
    value: number;
    relation: string;
  };
  maxScore: number;
  items: MegaTrendData[];
}

export interface MegaTrendData {
  crawlDttm: string;
  year: string;
  source: string;
  keyword: string;
  content: string;
  url: string;
  fileName: string;
  fileDirectoryPath: string;
  category: string;
  domesticOverseas: string;
  loadYmd: string;
  loadTime: string;
  imagePath: string;
}

export interface ModuleDetail {
  isDetailOpen: boolean;
  detailData?: MegaTrendData;
}
