import BaseService from '../BaseService';
import { CommonResponse } from '../model/CommonResponse';
import { Method } from '../model/Method';
import { Service } from '../model/Service';
import { ShareRequest } from './model/Share';

export default class ShareService extends BaseService {
  async saveShare(share: ShareRequest): Promise<CommonResponse> {
    const url = '/v1/share';
    const serviceName = Service.MIBE;
    const method = Method.POST;
    const params = null;
    const body = share;
    let response: CommonResponse;

    try {
      response = await this.fnRest(method, url, params, body, serviceName);
    } catch (error) {
      response = error;
    }
    return response;
  }
  getAvatarFileName(avatarType: string): string {
    return 'ic-avatar-' + avatarType + '.png';
  }
  getMatchKeywordBold(target: string, keyword: string): string{
    return target.replace(new RegExp("("+keyword+")","i"), "<b>$1</b>");
  }
}
