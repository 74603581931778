export enum GoogleSearch {
  GOOGLE_SEARCH_URL = 'http://www.google.com/search?q=',
}

export enum SearchMenuType {
  UNIFIED = 0,
  MI_MATERIAL = 1,
  NEWS_REPORT = 2,
  GOOGLE = 7,
}

export enum SearchCondition {
  DEFAULT_PAGE_COUNT = 10,
  UNIFIED_SEARCH_COUNT = 5,
  START_PAGE_NUMBER = 1,
}

export enum RelationCode {
  GTE = 'gte',
}

export enum SearchDetailDefaultProps {
  width = 800,
  top = 90,
}

export enum KeywordCategoryCode {
  INDUSTRY = '1001',
  COUNTRY = '1002',
  TECHNOLOGY = '1003',
  PRODUCT = '1004',
  COMPETITOR = '1005',
}

export enum RecommendedSearch {
  COOKIE_NAME = 'recommendedSearch',
  TOGGLE_ON = 'on',
  TOGGLE_OFF = 'off',
}

export enum SearchType {
  GOOGLE = 'GOOGLE',
  MY_WORK = 'MY_WORK',
  NEWS = 'NEWS',
}

export enum ActionFlag {
  CLICK = 'C',
  BOOKMARK = 'B',
}