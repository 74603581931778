export interface SessionInfo {
  sessionId: string;
  memberId: number;
  memberKorName: string;
  memberEngName: string;
  tenantId: string;
  memberCompany: string;
  empNo: string;
  deptId: string;
  avatarType: string;
  avatarBackgroundColor: string;
  jobTitle: string;
  deptKorName: string;
  deptEngName: string;
}

export enum Session {
  SESSION_ID = 'sessionId',
  MEMBER_ID = 'memberId',
  MEMBER_KOR_NAME = 'memberKorName',
  MEMBER_ENG_NAME = 'memberEngName',
  TENANT_ID = 'tenantId',
  MEMBER_COMPANY = 'memberCompany',
  EMPLOYEE_NUMBER = 'empNo',
  DEPARTMENT_ID = 'deptId',
  AVATAR_TYPE = 'avatarType',
  AVATAR_BACKGROUND_COLOR = 'avatarBackgroundColor',
  JOB_TITLE = 'jobTitle',
  DEPT_KOR_NAME = 'deptKorName',
  DEPT_ENG_NAME = 'deptEngName',
  COOKIE_NAME = 'sessionInfo',
}