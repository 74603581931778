import React, { useContext } from 'react';
import { Image } from 'react-bootstrap';
import { AuthContext } from '../../../App';
import { MemberDisplayType } from '../../../service/share/model/enum/Share.enum';
import './ShareMember.scss';

export interface MemberProps {
  memberId: number;
  avatarType: string;
  memberKorName: string;
  memberEngName: string;
  jobTitle: string;
  memberCompany?: string;
  deptKorName?: string;
  avatarBackgroundColor: string;
  memberDisplayType: MemberDisplayType;
  keyword?: string;
}

export const ShareMember: React.FC<MemberProps> = (props: MemberProps) => {
  const { state, dispatch } = useContext(AuthContext);

  return (
    <div id="ShareMember">
      <Image
        src={require(`../../../icons/${props.avatarType}`)}
        style={{ backgroundColor: props.avatarBackgroundColor }}
      />
      <span className="memberInfo">
        {props.keyword ? (
          <span
            className="memberKorName"
            dangerouslySetInnerHTML={{
              __html: state.shareService.getMatchKeywordBold(props.memberKorName, props.keyword),
            }}
          />
        ) : (
          <span className="memberKorName">{props.memberKorName}</span>
        )}
        <span className="jobTitle">{props.jobTitle}</span>

        {props.memberDisplayType === MemberDisplayType.SEARCHED &&
          (props.keyword ? (
            <span
              className="memberEngName"
              dangerouslySetInnerHTML={{
                __html: state.shareService.getMatchKeywordBold(props.memberEngName, props.keyword),
              }}
            />
          ) : (
            <span className="memberEngName">({props.memberEngName})</span>
          ))}
      </span>
      {(props.memberDisplayType === MemberDisplayType.SEARCHED && props.deptKorName && props.keyword) &&(
        <span className="companyInfo">
          <span className="memberCompany">{props.memberCompany}</span>
          <span
              className="department"
              dangerouslySetInnerHTML={{
                __html: state.shareService.getMatchKeywordBold(props.deptKorName, props.keyword),
              }}
            />
        </span>
      )}
    </div>
  );
};
export default ShareMember;
