export enum SearchCondition {
  DEFAULT_PAGE_COUNT = 500,
  UNIFIED_SEARCH_COUNT = 5,
  START_PAGE_NUMBER = 1,
}

export enum DomesticOverseasCode {
  OVERSEAS = '해외',
}

export enum SourceCode {
  OVERSEAS = '해외전체',
  DOMESTIC = '국내전체',
}

export enum CategoryCode {
  ECONOMIC = 'Economic',
  ENVIRONMENT = 'Environment',
  POLITICAL = 'Political',
  SOCIAL = 'Social',
  TECHNOLOGICAL = 'Technological',
}

export enum ModuleDetailDefaultProps {
  width = 800,
  top = 90,
}