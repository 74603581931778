import BookmarkService from '../service/bookmark/BookmarkService';
import CookieService from '../service/CookieService';
import DocumentService from '../service/document/DocumentService';
import { DocumentViewType } from '../service/document/model/enum/Document.enum';
import InterestService from '../service/interests/InterestService';
import MegaTrendService from '../service/megaTrend/MegaTrendService';
import MemberService from '../service/member/MemberService';
import NoticeService from '../service/notice/NoticeService';
import GoogleSearchService from '../service/search/GoogleSearchService';
import YoutubeService from '../service/youtube/YoutubeService';
import SearchService from '../service/search/SearchService';
import { ShareType } from '../service/share/model/enum/Share.enum';
import ShareService from '../service/share/ShareService';
import TableauService from '../service/tableau/TableauService';
import TermService from '../service/terms/TermsService';
import {
  Action,
  ActionType,
  BookmarkAction,
  BookmarkActionType,
  DocumentAction,
  DocumentActionType,
  GoogleSearchAction,
  GoogleSearchActionType,
  MiMaterialAction,
  MiMaterialActionType,
  NewsAction,
  NewsActionType,
  SearchAction,
  SearchActionType,
  MegaTrendAction,
  MegaTrendActionType,
  MegaTrendSourceAction,
  MegaTrendSourceActionType,
  MegaTrendCategoryYearAction,
  MegaTrendCategoryYearActionType,
  MegaTrendDetailAction,
  MegaTrendDetailActionType,
  ShareAction,
  ShareActionType,
  NoticeAction,
  NoticeActionType,
} from './actions';
import { State } from './states';

export const initialState: State = {
  termsService: new TermService(),
  searchService: new SearchService(),
  bookmarkService: new BookmarkService(),
  documentService: new DocumentService(),
  googleSearchService: new GoogleSearchService(),
  tableauService: new TableauService(),
  megaTrendService: new MegaTrendService(),
  cookieService: new CookieService(),
  interestService: new InterestService(),
  youtubeService: new YoutubeService(),
  shareService: new ShareService(),
  noticeService: new NoticeService(),
  memberService: new MemberService(),
  showDocumentDrawer: false,
  document: {
    viewType: DocumentViewType.LIST,
    selectedFolderId: '',
  },
  /* eslint-disable */
  news: {
    timedOut: false,
    total: {
      value: 0,
      relation: '',
    },
    maxScore: 0,
    items: [],
  },
  /* eslint-enable */
  google: {
    searchResults: [],
    pagingInfo: {
      totalCount: 0,
      countPerPage: 0,
      startIndex: 0,
      endIndex: 0,
    },
  },
  /* eslint-disable */
  megaTrendSource: {
    timedOut: false,
    total: {
      value: 0,
      relation: '',
    },
    maxScore: 0,
    items: [],
  },
  /* eslint-enable */
  megaTrendCategoryYear: {
    categoryResult: [],
    yearResult: [],
  },
  /* eslint-disable */
  megaTrendData: {
    timedOut: false,
    total: {
      value: 0,
      relation: '',
    },
    maxScore: 0,
    items: [],
  },
  /* eslint-enable */
  search: {
    isEnter: false,
  },
  searchDetail: {
    searchData: { title: '', content: '' },
    isDetailOpen: false,
  },
  searchRight: {
    isShowRight: false,
  },
  isInitialPage: false,
  miMaterial: {
    total: {
      value: 0,
    },
    items: [],
  },
  moduleDetail: {
    detailData: { crawlDttm: '',
      year: '',
      source: '',
      keyword: '',
      content: '',
      url: '',
      fileName: '',
      fileDirectoryPath: '',
      category: '',
      domesticOverseas: '',
      loadYmd: '',
      loadTime: '',
      imagePath: '', },
    isDetailOpen: false,
  },
  bookmark: {
    showBookmark: false,
    folderId: 0,
    isInitial: true,
    isAddFolder: false,
    isExpand: false,
    isAddContents: false,
  },
  searchResult: false,
  menuLv2: undefined,
  share: {
    show: false,
    sharedType: ShareType.NONE,
    sharedDetails: [],
    sharedMembers: [],
  },
  notice: {
    show: false,
  },
};

export const reducer = (
  state: State,
  action:
    | Action
    | DocumentAction
    | NewsAction
    | SearchAction
    | GoogleSearchAction
    | MiMaterialAction
    | BookmarkAction
    | MegaTrendAction
    | MegaTrendSourceAction
    | MegaTrendCategoryYearAction
    | ShareAction
    | MegaTrendDetailAction
    | NoticeAction
) => {
  switch (action.type) {
    case ActionType.LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ActionType.SHOW_TOAST:
      return {
        ...state,
        toast: action.toast,
      };
    case ActionType.SHOW_DOCUMENT_DRAWER:
      return {
        ...state,
        showDocumentDrawer: action.showDocumentDrawer,
      };
    case ActionType.SHOW_YOUTUBE_DRAWER:
      return {
        ...state,
        showYoutubeDrawer: action.showYoutubeDrawer,
      };  
    case ActionType.SEARCH:
      return {
        ...state,
        search: action.search,
      };
    case ActionType.SET_SEARCH_RESULT:
      return {
        ...state,
        searchResult: action.searchResult,
      };
    case ActionType.CHANGE_MENU:
      return {
        ...state,
        menuLv2: action.menuLv2,
      };
    case DocumentActionType.SET_DOCUMENT_VIEW_LIST:
      return {
        ...state,
        document: {
          ...state.document,
          viewType: DocumentViewType.LIST,
          selectedFolderId: action.selectedFolderId,
        },
      };
    case DocumentActionType.SET_DOCUMENT_VIEW_EDITOR:
      return {
        ...state,
        document: {
          ...state.document,
          viewType: DocumentViewType.EDITOR,
          selectedFolderId: action.selectedFolderId,
          editorContent: action.editorContent,
          documentEditor: action.documentEditor,
        },
      };
    case DocumentActionType.SET_DOCUMENT_EDITOR_CONTENT:
      return {
        ...state,
        document: {
          ...state.document,
          editorContent: action.editorContent,
        },
      };
    case DocumentActionType.SET_DOCUMENT_EDITOR:
      return {
        ...state,
        document: {
          ...state.document,
          editorContent: action.editorContent,
          documentEditor: action.documentEditor,
        },
      };
    case DocumentActionType.SET_DOCUMENT_VIEW_CONTENT:
      return {
        ...state,
        document: {
          ...state.document,
          viewType: DocumentViewType.VIEWER,
          selectedFolderId: action.selectedFolderId,
          editorContent: action.editorContent,
          documentEditor: action.documentEditor,
        },
      };
    case NewsActionType.SET_NEWS_VIEW_LIST:
      return {
        ...state,
        /* eslint-disable */
        news: {
          ...state.news,
          timed_out: action.newsDataList.timedOut,
          total: action.newsDataList.total,
          max_score: action.newsDataList.maxScore,
          items: action.newsDataList.items,
        },
        /* eslint-enable */
      };
    case GoogleSearchActionType.SET_GOOGLE_RESULT_LIST:
      return {
        ...state,
        google: {
          searchResults: action.searchResults,
          pagingInfo: action.pagingInfo,
        },
      };
    case MegaTrendSourceActionType.SET_MEGA_TREND_SOURCE_LIST:
      return {
        ...state,
        /* eslint-disable */
        megaTrendSource: {
          ...state.megaTrendSource,
          timed_out: action.megaTrendSourceList.timedOut,
          total: action.megaTrendSourceList.total,
          max_score: action.megaTrendSourceList.maxScore,
          items: action.megaTrendSourceList.items,
        },
        /* eslint-enable */
      };
    case MegaTrendCategoryYearActionType.SET_MEGA_TREND_CATEGORY_YEAR_LIST:
      return {
        ...state,
        megaTrendCategoryYear: {
          categoryResult: action.categoryResult,
          yearResult: action.yearResult,
        },
      };
    case MegaTrendActionType.SET_MEGA_TREND_RESULT_LIST:
      return {
        ...state,
        /* eslint-disable */
        megaTrendData: {
          ...state.megaTrendData,
          timed_out: action.megaTrendDataList.timedOut,
          total: action.megaTrendDataList.total,
          max_score: action.megaTrendDataList.maxScore,
          items: action.megaTrendDataList.items,
        },
        /* eslint-enable */
      };
    case MegaTrendDetailActionType.SHOW_MODULE_DETAIL:
      return {
        ...state,
        moduleDetail: action.moduleDetail,
      };
    case SearchActionType.SET_IS_INITIAL_PAGE:
      return {
        ...state,
        isInitialPage: action.isInitialPage,
      };
    case SearchActionType.SHOW_SEARCH_DETAIL:
      return {
        ...state,
        searchDetail: action.searchDetail,
      };
    case SearchActionType.SHOW_SEARCH_RIGHT:
      return {
        ...state,
        searchRight: action.searchRight,
      };
    case SearchActionType.HIDE_SEARCH_RIGHT:
      return {
        ...state,
        searchRight: {
          ...state.searchRight,
          isShowRight: action.searchRight.isShowRight,
        },
      };
    case SearchActionType.TOGGLE_RECOMMENDED_SEARCH:
      return {
        ...state,
        searchRight: {
          ...state.searchRight,
          recommendedSearch: action.searchRight.recommendedSearch,
        },
      };
    case MiMaterialActionType.SET_MI_MATERIAL_RESULT:
      return {
        ...state,
        miMaterial: {
          ...state.miMaterial,
          ...action.miMaterial,
        },
      };
    case BookmarkActionType.SHOW_BOOKMARK:
      return {
        ...state,
        bookmark: {
          ...state.bookmark,
          showBookmark: action.showBookmark,
        },
      };
    case BookmarkActionType.ADD_FOLDER:
      return {
        ...state,
        bookmark: {
          ...state.bookmark,
          isAddFolder: action.isAddFolder,
        },
      };
    case BookmarkActionType.SET_IS_INITIAL:
      return {
        ...state,
        bookmark: {
          ...state.bookmark,
          isInitial: action.isInitial,
        },
      };
    case BookmarkActionType.EXPAND_BOOKMARK:
      return {
        ...state,
        bookmark: {
          ...state.bookmark,
          isExpand: action.isExpand,
          folderId: action.folderId,
        },
      };
    case BookmarkActionType.ADD_CONTENTS:
      return {
        ...state,
        bookmark: {
          ...state.bookmark,
          isAddContents: action.isAddContents,
        },
      };
    case ShareActionType.SHOW_SHARE_MODAL:
      return {
        ...state,
        share: {
          ...state.share,
          show: action.show,
          sharedType: action.sharedType,
          sharedDetails: action.sharedDetails,
        },
      };
    case ShareActionType.SET_SHARED_DETAILS:
      return {
        ...state,
        share: {
          ...state.share,
          sharedDetails: action.sharedDetails,
        },
      };
    case ShareActionType.ADD_MEMBERS:
      return {
        ...state,
        share: {
          ...state.share,
          sharedMembers: action.sharedMembers,
        },
      };
    case NoticeActionType.SHOW_NOTICE_DRAWER:
      return {
        ...state,
        showNoticeDrawer: action.showNoticeDrawer,
      };
    default:
      return initialState;
  }
};
