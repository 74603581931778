export enum ShareType {
  BOOKMARK = 'BOOKMARK',
  MI_NOTE = 'MI_NOTE',
  NEWS = 'NEWS',
  MEGA_TREND = 'MEGA_TREND',
  NONE = '',
}

export enum MemberDisplayType {
  SELECTED = 'SELECTED',
  SEARCHED = 'SEARCHED',
}

export enum ShareConstant {
  SEARCH_START_SIZE = 2,
  VISIBLE = "userList-visible",
  INVISIBLE = "userList-invisible",
  SHARE_CONTENT_MAX_LENGTH = 150,
  SHARE_CONTENT_ROW = 3,
}
